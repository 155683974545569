<template>
    <footer class="footer-section w-ba">
        <div class="container max-width-gib">
            <div class="row">
                <div class="provider-logo">
                    <img src="../assets/images/partner/ag.png">
                    <img src="../assets/images/partner/microgaming.png">
                    <img src="../assets/images/partner/evolution.png">
                    <img src="../assets/images/partner/pragmatic.png">
                    <img src="../assets/images/partner/oriental.png">
                    <img src="../assets/images/partner/sexygaming.png">
                    <img src="../assets/images/partner/dreamgaming.png">
                    <img src="../assets/images/partner/cq9.png">
                    <img src="../assets/images/partner/bng.png">
                    <img src="../assets/images/partner/habanero.png">
                    <img src="../assets/images/partner/bgaming.png">
                    <img src="../assets/images/partner/stargame.png">
                    <img src="../assets/images/partner/evoplay.png">
                    <img src="../assets/images/partner/aristocrat.png">
                    <img src="../assets/images/partner/playngo.png">
                    <img src="../assets/images/partner/genesis.png">
                </div>
            </div>
            <div class="copyright w-ba">
                <!-- <img src="../assets/images/logo-footer.png?v14"> -->
                <p class="mb-0 mt-3">ⓒ COPYRIGHT king88life ALL RIGHTS RESERVED.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer_city',
  data () {
    return {
    }
  }
}
</script>
<style>
</style>
