<template>
    <div class="wrapper-form wrapper-form_edit page-content">
        <div class="frm_transfer">
            <form method="post" @submit="cashOut">
                <p class="p_text">Ví tổng</p>
                <div class="info-money">
                    <input type="text" disabled :value="new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(wallet) + ' vnđ'" class="moneyinfo">
                </div>
                <p class="p_text">Số tiền</p>
                <div class="money-transfer">
                    <!-- <input type="number" placeholder="0" name="amount" v-model="amount" class="numbermoney"> -->
                    <input v-numeric-input="{ thousands: ',', align: 'left'}" class="numbermoney moneyCashout" placeholder="0" />
                </div>
                <p class="p_text">Số tài khoản</p>
                <div class="money-transfer">
                    <input type="number" disabled style="cursor: no-drop;" placeholder="0" v-model="bank_number" class="numbermoney">
                </div>
                <!-- <p class="p_text">Ngân hàng</p>
                <div class="bankItems">
                    <Multiselect
                        v-model="bank"
                        :options="listBank"
                        :searchable="true"
                        placeholder="Ngân hàng"
                        label="name"
                    />
                </div> -->
                <p class="p_text">Ngân hàng</p>
                <div class="wpImg_bank">
                    <img :src="api_linkweb + imgBank" alt="" v-if="imgBank != ''">
                    <input disabled style="cursor: no-drop;" v-model="bank" class="namebankCode" placeholder="Ngân hàng" />
                </div>
                <p class="p_text">Tên người thụ hưởng</p>
                <div class="money-transfer">
                    <input type="text" disabled style="cursor: no-drop;" placeholder="0" v-model="beneficiary_name" class="numbermoney">
                </div>
                <div class="btn-cashin">
                    <button type="submit" class="btn_submit_transfer">Gửi</button>
                </div>
            </form>
        </div>
        <div class="wrapper-listmoney">
            <h2 class="title_transfer">Lịch sử rút tiền</h2>
            <div class="table-list">
                <table>
                    <thead>
                        <tr>
                            <th style="width: 5%;">STT</th>
                            <th style="width: 15%;">Số tiền rút</th>
                            <th style="width: 10%;">Ngân hàng</th>
                            <th>Số tài khoản</th>
                            <th>Người thụ hưởng</th>
                            <th>Ngày rút (GMT +8)</th>
                            <th style="width: 10%;">Trạng thái</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data" :key="index">
                            <td style="width: 5%;">{{ index+1 }}</td>
                            <td style="width: 15%;">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                            <td style="width: 10%;">{{ item.bank_code }}</td>
                            <td>{{ item.bank_number }}</td>
                            <td>{{ item.beneficiary_name }}</td>
                            <td>{{ item.date_created }}</td>
                            <td style="width: 10%;" v-if="item.situation == 'new'" class="yellowColor">Chờ duyệt</td>
                            <td style="width: 10%;" v-else-if="item.situation == 'approve'" class="greenColor">Đã duyệt</td>
                            <td style="width: 10%;" v-else class="redColor">Hủy</td>

                            <td v-if="item.situation == 'new'" class="btnHuycashout" @click="cancalCashout($event)" :data_id="item.id"><span :data_id="item.id">Hủy</span></td>
                            <td v-else></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
// import Multiselect from '@vueform/multiselect'
$( document ).ready(function() {
});
export default {
    name: 'Cashin_city',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            wallet: '',
            amount: '',
            listBank: [],
            bank: '',
            banks: '',
            bank_number: '',
            beneficiary_name: '',
            listHistoryCashout: [],
            page: 1,
            perPage: 15,
            totalCount: 0,
            data: [],
            imgBank: ''
        }
    },
    created () {
        this.getListInfo()
        this.getListBank()
        this.getHistoryCashout()
    },
    components: {
        // Multiselect
    },
    methods: {
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.wallet = response.data.wallet
                this.bank_number = response.data.bank_number
                this.beneficiary_name = response.data.beneficiary_name
                // this.bank = response.data.bank_code
                let bank = response.data.bank_code
                this.axios.get(this.api_listBankOut, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((response) => {
                    this.listBank = response.data.data.filter(d => d.value == bank)
                    this.imgBank = this.listBank[0].image
                    this.bank = this.listBank[0].name
                })
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                // localStorage.clear();
                // window.location.href = '/'
            })
        },
        getListBank () {
            this.axios.get(this.api_listBankOut, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listBank = response.data.data
            })
        },
        cashOut (e) {
            $('.loading_show').show().fadeIn(10)
            e.preventDefault()
            let amountCash = $('.numbermoney').val()
            this.axios.post(this.api_cashout, 
                {
                    balance: amountCash,
                    // bank_code: this.bank,
                    // bank_number: this.bank_number,
                    // beneficiary_name: this.beneficiary_name
                },
                { headers: {
                    Authorization: 'Bearer ' + this.token
                }},
            ).then(res => {
                $('#loading_show').css('display', 'none')
                $('.moneyCashout').val('')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                this.getHistoryCashout()
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                $('#loading_show').css('display', 'none')
            })
        },
        getHistoryCashout () {
            this.axios.get(this.api_historyCashout, 
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listHistoryCashIn = response.data.data
                this.totalCount = this.listHistoryCashIn.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.data = this.listHistoryCashIn.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        cancalCashout (event) {
            $('.loading_show').show().fadeIn(10)
            let id = event.target.getAttribute('data_id')
            this.axios.post(this.api_cancalCashout, 
                {
                    id: id
                },
                {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.getHistoryCashout()
            })
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
