<template>
    <div class="wrapper-convert page-content wrapper-convertBetting">
        <div class="wrapper-listmoney wrapper-listmoneysss">
            <!-- <h2 class="title_transfer">Lịch sử cá cược</h2> -->
            <div class="tab_history">
                <ul>
                    <li :class="{active: tabSelected === 'tab1'}" v-on:click="changeTab('tab1')">Lịch sử</li>
                    <li :class="{active: tabSelected === 'tab2'}" v-on:click="changeTab('tab2')">Thống kê</li>
                </ul>
            </div>
            <div class="root_tab_transfer">
                <div v-if="tabSelected === 'tab1'">
                    <div class="frmdate_report">
                        <div class="datestart">
                            <p class="title_date">Từ ngày</p>
                            <Datepicker v-model="dateStart" />
                        </div>
                        <div class="dateend">
                            <p class="title_date">Đến ngày</p>
                            <Datepicker v-model="dateEnd" />
                        </div>
                        <div class="submitdate">
                            <p class="title_date title_datenone">kq</p>
                            <button type="button" @click="setDateHistory()">Tìm kiếm</button>
                        </div>
                    </div>
                    <div class="table-list">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width: 5%;">STT</th>
                                    <th>Tiền cược</th>
                                    <th>Game</th>
                                    <th>Kết quả</th>
                                    <th>Ngày chơi (GMT +8)</th>
                                    <th>Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody v-if="listBetting.length > 0">
                                <tr v-for="(item, index) in data" v-bind:key="index">
                                    <td style="width: 5%;">{{ index+1 }}</td>
                                    <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                    <td>{{ item.game_name }}</td>

                                    <td v-if="item.win > item.amount" class="greenColor">Thắng</td>
                                    <td v-else-if="item.win == item.amount" class="blueColor">Hòa</td>
                                    <td v-else class="redColor">Thua</td>

                                    <td>{{ item.date_bet }}</td>
                                    <td v-if="item.situation == 'new'">Đang chờ</td>
                                    <td v-else-if="item.situation == 'settle'">Thành công</td>
                                    <td v-else-if="item.situation == ''">Xóa</td>
                                    <td v-else>Hủy</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5">Dữ liệu trống</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                </div>
                <div v-if="tabSelected === 'tab2'">
                    <div class="frmdate_report">
                        <div class="datestart">
                            <p class="title_date">Từ ngày</p>
                            <Datepicker v-model="dateStart" />
                        </div>
                        <div class="dateend">
                            <p class="title_date">Đến ngày</p>
                            <Datepicker v-model="dateEnd" />
                        </div>
                        <div class="submitdate">
                            <p class="title_date title_datenone">kq</p>
                            <button type="button" @click="setDateReport()">Tìm kiếm</button>
                        </div>
                    </div>
                    <div class="table-list">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width: 5%;">STT</th>
                                    <th>Tổng cược</th>
                                    <th>Tổng thắng</th>
                                    <th>Ngày chơi</th>
                                    <th>Lợi nhuận</th>
                                </tr>
                            </thead>
                            <tbody v-if="listReportBet.length > 0">
                                <tr v-for="(item, index) in data1" v-bind:key="index">
                                    <td style="width: 5%;">{{ index+1 }}</td>
                                    <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.bet) }}</td>
                                    <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.win) }}</td>
                                    <td>{{ convertDate(item.day) }}</td>
                                    <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.win - item.bet) }}</td>
                                </tr>
                                <tr class="trYellow">
                                    <td colspan="4">TỔNG LỢI NHUẬN</td>
                                    <td>{{totalItem}}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5">Dữ liệu trống</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <paginate @update:modelValue="clickCallback1" :totalCount="totalCount1" :limit="perPage1" v-model="page1"></paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Datepicker from 'vue3-datepicker'
$( document ).ready(function() {
});

export default {
    name: 'Betting_city',
    data () {
        return {
            listBetting: [],
            page: 1,
            perPage: 25,
            totalCount: 0,
            data: [],
            token: window.localStorage.getItem('token'),
            tabSelected: 'tab1',
            listReportBet: [],
            dateStart: new Date(),
            dateEnd: new Date(),
            checkClickDate: 0,

            dateStartSort: '',
            dateEndSort: '',
            page1: 1,
            perPage1: 25,
            totalCount1: 0,
            data1: [],
        }
    },
    created () {
        this.getListBetting()
        this.getListReportBet()
    },
    components: {
        Datepicker
    },
    computed: {
        totalItem () {
            let sum = 0;
            this.data1.forEach(function(item) {
                sum += (parseFloat(item.win - item.bet));
            });
            return sum;
        }
    },
    methods: {
        changeTab (tab) {
            this.tabSelected = tab
        },
        setDateHistory () {
            $('.loading_show').show().fadeIn(10)
            this.checkClickDate = 1
            this.dateStartSort = this.formatDate(this.dateStart.toLocaleDateString("en-US"))
            this.dateEndSort = this.formatDate(this.dateEnd.toLocaleDateString("en-US"))
            this.axios.get(this.api_historyBetting + '?time_start=' + this.dateStartSort + '&time_end=' + this.dateEndSort, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                $('#loading_show').css('display', 'none')
                this.listBetting = response.data.data
                this.totalCount = this.listBetting.length
                this.paginatedData()
            })
        },
        getListBetting () {
            if(this.checkClickDate == 0){
                let datenow = this.dateStart.setDate(this.dateStart.getDate() - 7);
                let datetime7 = new Date(datenow)

                this.dateStartSort = this.formatDate(datetime7.toLocaleDateString("en-US"))
                this.dateEndSort = this.formatDate(this.dateEnd.toLocaleDateString("en-US"))
            }
            // console.log(this.dateStartSort, this.dateEndSort);
            this.axios.get(this.api_historyBetting + '?time_start=' + this.dateStartSort + '&time_end=' + this.dateEndSort, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listBetting = response.data.data
                this.totalCount = this.listBetting.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.data = this.listBetting.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        setDateReport () {
            $('.loading_show').show().fadeIn(10)
            this.checkClickDate = 1
            this.dateStartSort = this.formatDate(this.dateStart.toLocaleDateString("en-US"))
            this.dateEndSort = this.formatDate(this.dateEnd.toLocaleDateString("en-US"))
            this.axios.get(this.api_reportBet + '?time_start=' + this.dateStartSort + '&time_end=' + this.dateEndSort, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                $('#loading_show').css('display', 'none')
                this.listReportBet = response.data.data
            })
        },
        getListReportBet () {
            if(this.checkClickDate == 0){
                this.dateStartSort = this.formatDate(this.dateStart.toLocaleDateString("en-US"))
                this.dateEndSort = this.formatDate(this.dateEnd.toLocaleDateString("en-US"))
            }
            this.axios.get(this.api_reportBet + '?time_start=' + this.dateStartSort + '&time_end=' + this.dateEndSort, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                // console.log('111', response.data.data);
                this.listReportBet = response.data.data
                this.totalCount1 = this.listReportBet.length
                this.paginatedData1()
            })
        },
        paginatedData1 () {
            this.data1 = this.listReportBet.slice((this.page1 - 1) * this.perPage1, this.page1 * this.perPage1)
        },
        clickCallback1 (pageNum) {
            this.page1 = pageNum;
            this.paginatedData1();
        },
        convertDate (d) {
            let arr = d.split('-')
            return arr[2] + '-' + arr[1] + '-' + arr[0]
        },
        formatDate (d) {
            let arr = d.split('/')
            return arr[1] + '-' + arr[0] + '-' + arr[2]
        },
    }
}
</script>
<style>
</style>
