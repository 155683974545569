<template>
    <div class="wraper-root">
        <main class="page-content w-ba">
            <section class="toggle-section">
                <div class="container max-width-gib">
                    <div  v-for="(items, index) in listgameperfect" :key="index">
                        <div class="slot-section sc-section active" v-if="dataGame[items[0].platform].length > 0">
                            <h2 class="namegame_title">{{ items[0].name }}</h2>
                            <div class="dflex-ac-jc w-100">
                                <a class="sc-btn w-ba" style="animation-delay: 0s;" v-for="(item, index) in dataGame[items[0].platform]" v-bind:key="index">
                                    <div class="g-panel w-ba">
                                        <div class="g-cont">
                                            <img class="g-img" :src="api_linkweb + item.images" v-if="item.images != null">
                                            <img class="g-img" src="../assets/images/main_gamenetgaming.png" v-else>
                                            <button type="button" class="play-btn btn-yellows" @click="getGameProcess(item.id)">Play</button>
                                        </div>
                                        <div class="g-info">
                                            <span class="g-name">{{ item.name }}</span>
                                        </div>
                                        <div class="glass"></div>
                                    </div>
                                </a>
                            </div>
                            <p class="loadsmore_listgame" v-if="length_listgame[items[0].platform] < dataGames[items[0].platform].length"><span @click="loadMore(items[0].platform)">Xem thêm</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Home_king',
    data () {
        return {
            tabSelected: 'tab1',
            showModal: false,
            token: window.localStorage.getItem('token'),
            windowWidth: window.innerWidth,
            screenW: '',
            
            dataGame: [],
            dataGames: [],
            out: [],
            listgameperfect: [],
            length_listgame: [],
        }
    },
    computed: {
        
    },
    components: {
    },
    created () {
        this.getListGame()
    },
    methods: {
        changeTab (tab) {
            this.tabSelected = tab
        },
        getListGame () {
            this.axios.get(this.api_listGame, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listgameperfect = response.data.data
                response.data.data.forEach(element => {
                    this.out[element[0].platform] = element[0].list_game;
                    this.length_listgame[element[0].platform] = 18;
                    this.dataGames[element[0].platform] = this.out[element[0].platform];
                    this.dataGame[element[0].platform] = this.out[element[0].platform].slice(0, 18);
                });
            })
        },
        loadMore(platform) {
            if (this.length_listgame[platform] > this.out[platform].length) return;
            this.length_listgame[platform] = this.length_listgame[platform] + 6;
            this.dataGame[platform] = this.out[platform].slice(0, this.length_listgame[platform]);
        },
        getGameProcess (code) {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_playGame, 
                {
                    game_id: code,
                },
                { headers: {
                    Authorization: 'Bearer ' + this.token
                }
            },
            ).then((response) => {
                var size = {
                    width: window.innerWidth || document.body.clientWidth,
                    height: window.innerHeight || document.body.clientHeight
                };
                var width = size.width;
                var height = size.height;
                var left = (window.screen.width / 2) - (width/2);
                var top = (window.screen.height / 4);
                var windowStatus = 'width='+width+', height='+height+', left='+left+', top='+top+', scrollbars=yes, status=yes, resizable=yes, titlebar=yes';
                const url = response.data.url;
                window.open(url, "gamepopup", windowStatus);
                $('#loading_show').css('display', 'none')
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                $('#loading_show').css('display', 'none')
            })
        },
    }
}
</script>
<style>
</style>
