<template>
    <div class="wrapper-convert page-content">
        <div class="wrapper_form_convert">
            <div class="tab_transfer">
                <ul>
                    <li :class="{active: tabSelected === 'tab1'}" v-on:click="changeTab('tab1')">Ví tổng sang game</li>
                    <li :class="{active: tabSelected === 'tab2'}" v-on:click="changeTab('tab2')">Ví tổng sang idol</li>
                    <li :class="{active: tabSelected === 'tab3'}" v-on:click="changeTab('tab3')">Game sang ví tổng</li>
                </ul>
            </div>
            <div class="root_tab_transfer">
                <div v-if="tabSelected === 'tab1'">
                    <div class="frm_transfer">
                        <form method="post" @submit="walletToGame">
                            <p class="p_text">Ví tổng</p>
                            <div class="info-money">
                                <input type="text" :value="new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(wallet) + ' vnđ'" class="moneyinfo">
                            </div>
                            <p class="p_text">Số tiền chuyển</p>
                            <div class="money-transfer">
                                <!-- <input type="number" placeholder="0" name="amount" v-model="amount" class="numbermoney"> -->
                                <input v-numeric-input="{ thousands: ',', align: 'left'}" class="numbermoney" placeholder="0" />
                            </div>
                            <div class="btn-cashin">
                                <button type="submit" class="btn_submit_transfer">Gửi</button>
                            </div>
                        </form>
                    </div>
                    <div class="wrapper-listmoney">
                        <h2 class="title_transfer">Lịch sử chuyển tiền vào ví game</h2>
                        <div class="table-list">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">STT</th>
                                        <th>Số tiền chuyển</th>
                                        <th>Số tiền quy đổi</th>
                                        <th>Ngày chuyển (GMT +8)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data1" :key="index">
                                        <td style="width: 5%;">{{ index+1 }}</td>
                                        <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                        <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount_change) }}</td>
                                        <td>{{ item.date_created }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <paginate @update:modelValue="clickCallback1" :totalCount="totalCount1" :limit="perPage1" v-model="page1"></paginate>
                    </div>
                </div>
                <div v-if="tabSelected === 'tab2'">
                    <div class="frm_transfer">
                        <form method="post" @submit="walletToidol">
                            <p class="p_text">Ví tổng</p>
                            <div class="info-money">
                                <input type="text" :value="new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(wallet) + ' vnđ'" class="moneyinfo">
                            </div>
                            <p class="p_text">Số tiền chuyển</p>
                            <div class="money-transfer">
                                <!-- <input type="number" placeholder="0" name="amount" v-model="amount1" class="numbermoney"> -->
                                <input v-numeric-input="{ thousands: ',', align: 'left'}" class="numbermoney" placeholder="0" />
                            </div>
                            <div class="btn-cashin">
                                <button type="submit" class="btn_submit_transfer">Gửi</button>
                            </div>
                        </form>
                    </div>
                    <div class="wrapper-listmoney">
                        <h2 class="title_transfer">Lịch sử chuyển tiền vào ví idol</h2>
                        <div class="table-list">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">STT</th>
                                        <th>Số tiền chuyển</th>
                                        <th>Số tiền quy đổi</th>
                                        <th>Ngày chuyển (GMT +8)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data2" :key="index">
                                        <td style="width: 5%;">{{ index+1 }}</td>
                                        <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                        <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount_change) }}</td>
                                        <td>{{ item.date_created }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <paginate @update:modelValue="clickCallback2" :totalCount="totalCount2" :limit="perPage2" v-model="page2"></paginate>
                    </div>
                </div>
                <div v-if="tabSelected === 'tab3'">
                    <div class="frm_transfer">
                        <form method="post" @submit="gametowallet">
                            <p class="p_text">Ví game</p>
                            <div class="info-money">
                                <input type="text" :value="new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(game) + ' xu'" class="moneyinfo">
                            </div>
                            <p class="p_text">Số tiền chuyển</p>
                            <div class="money-transfer">
                                <!-- <input type="number" placeholder="0" name="amount" v-model="amount2" class="numbermoney"> -->
                                <input v-numeric-input="{ thousands: ',', align: 'left'}" class="numbermoney" placeholder="0" />
                            </div>
                            <div class="btn-cashin">
                                <button type="submit" class="btn_submit_transfer">Gửi</button>
                            </div>
                        </form>
                    </div>
                    <div class="wrapper-listmoney">
                        <h2 class="title_transfer">Lịch sử chuyển tiền từ ví game vào ví tổng</h2>
                        <div class="table-list">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">STT</th>
                                        <th>Số tiền chuyển</th>
                                        <th>Số tiền quy đổi</th>
                                        <th>Ngày chuyển (GMT +8)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data3" :key="index">
                                        <td style="width: 5%;">{{ index+1 }}</td>
                                        <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                        <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount_change) }}</td>
                                        <td>{{ item.date_created }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <paginate @update:modelValue="clickCallback3" :totalCount="totalCount3" :limit="perPage3" v-model="page3"></paginate>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
$( document ).ready(function() {
});

export default {
    name: 'TransferMoney_king',
    data () {
        return {
            tabSelected: 'tab1',
            token: window.localStorage.getItem('token'),
            username: window.localStorage.getItem('usn'),
            wallet: '',
            game: '',
            amount: '',
            amount1: '',
            amount2: '',
            listHistory1: [],
            page1: 1,
            perPage1: 15,
            totalCount1: 0,
            data1: [],
            listHistory2: [],
            page2: 1,
            perPage2: 15,
            totalCount2: 0,
            data2: [],
            listHistory3: [],
            page3: 1,
            perPage3: 15,
            totalCount3: 0,
            data3: [],
        }
    },
    created () {
        this.getListInfo()
        this.getHistoryWalletToGame()
        this.getHistoryWalletToidol()
        this.getHistoryGameToWallet()
    },
    methods: {
        changeTab (tab) {
            this.tabSelected = tab
        },
        getListInfo () {
            this.axios.get(this.api_infouser, 
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.wallet = response.data.wallet
                this.game = response.data.amount
            })
        },
        walletToGame (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let moneyTran = $('.numbermoney').val()
            this.axios.post(this.api_wallettogame, 
                {
                    username: this.username,
                    balance: moneyTran
                },
                { 
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                $('.numbermoney').val('')
                this.axios.get(this.api_infouser, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((response) => {
                    $('.walletAll').text(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(response.data.wallet) + ' vnđ')
                    $('.walletgame').text(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(response.data.amount) + ' xu')
                    this.wallet = response.data.wallet
                    this.game = response.data.amount
                })
                this.getHistoryWalletToGame()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        gametowallet (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let moneyTran = $('.numbermoney').val()
            this.axios.post(this.api_gametowallet, 
                {
                    username: this.username,
                    balance: moneyTran
                },
                { 
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                $('.numbermoney').val('')
                this.axios.get(this.api_infouser, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((response) => {
                    $('.walletAll').text(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(response.data.wallet) + ' vnđ')
                    $('.walletgame').text(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(response.data.amount) + ' xu')
                    this.wallet = response.data.wallet
                    this.game = response.data.amount
                })
                this.getHistoryGameToWallet()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        walletToidol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let moneyTran = $('.numbermoney').val()
            this.axios.post(this.api_wallettolive, 
                {
                    username: this.username,
                    balance: moneyTran
                },
                { 
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                $('.numbermoney').val('')
                this.axios.get(this.api_infouser, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((response) => {
                    $('.walletAll').text(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(response.data.wallet) + ' vnđ')
                    $('.walletidol').text(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(response.data.point) + ' đậu')
                    this.wallet = response.data.wallet
                })
                this.getHistoryWalletToidol()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        getHistoryWalletToGame () {
            this.axios.get(this.api_historyWalletToGame, 
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listHistory1 = response.data.data
                this.totalCount1 = this.listHistory1.length
                this.paginatedData1()
            })
        },
        paginatedData1 () {
            this.data1 = this.listHistory1.slice((this.page1 - 1) * this.perPage1, this.page1 * this.perPage1)
        },
        clickCallback1 (pageNum) {
            this.page1 = pageNum;
            this.paginatedData1();
        },
        getHistoryWalletToidol () {
            this.axios.get(this.api_historyWalletToIdol, 
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listHistory2 = response.data.data
                this.totalCount2 = this.listHistory2.length
                this.paginatedData2()
            })
        },
        paginatedData2 () {
            this.data2 = this.listHistory2.slice((this.page2 - 1) * this.perPage2, this.page2 * this.perPage2)
        },
        clickCallback2 (pageNum) {
            this.page2 = pageNum;
            this.paginatedData2();
        },
        getHistoryGameToWallet () {
            this.axios.get(this.api_historyGameToWallet, 
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listHistory3 = response.data.data
                this.totalCount3 = this.listHistory3.length
                this.paginatedData3()
            })
        },
        paginatedData3 () {
            this.data3 = this.listHistory3.slice((this.page3 - 1) * this.perPage3, this.page3 * this.perPage3)
        },
        clickCallback3 (pageNum) {
            this.page3 = pageNum;
            this.paginatedData3();
        },
    }
}
</script>
<style>
</style>
