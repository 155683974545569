<template>
    <div>
        <Header_city />
            <router-view :key ='$route.params'/>
        <Footer_city/>
    </div>
    <!-- <div class="bgr_maintenance" v-else>
        <div class="container maintenance">
            <img src="./assets/images/system.png" alt="">
            <h1 class="error-title"><span>We’ll be back soon!</span></h1>
            <div v-html="message" class="text_maintenance"></div>
        </div>
    </div> -->
</template>
<script>
import "./assets/css/style.css"
import Header_city from './components/Header.vue'
import Footer_city from './components/Footer.vue'

export default {
    name: 'App',
    data () {
        return {
            // message: ''
        }
    },
    components: {
        Header_city,
        Footer_city
    },
    created () {
    },
    methods: {
    }
}
</script>

<style>
</style>
