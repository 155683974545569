import { createRouter, createWebHistory } from 'vue-router'

import Home_king from '@/components/Home.vue'
import Cashin_king from '@/components/Cashin.vue'
import Cashout_king from '@/components/Cashout.vue'
import TransferMoney_king from '@/components/TransferMoney.vue'
import Betting_king from '@/components/Betting.vue'

const routes = [
    {
        path: '/',
        name: 'Home_king',
        component: Home_king
    },
    {
        path: '/cash-in',
        name: 'Cashin_king',
        component: Cashin_king
    },
    {
        path: '/cash-out',
        name: 'Cashout_king',
        component: Cashout_king
    },
    {
        path: '/transfer-money',
        name: 'TransferMoney_king',
        component: TransferMoney_king
    },
    {
        path: '/betting',
        name: 'Betting_king',
        component: Betting_king
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: "active"
})

export default router