import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import NumericInput from 'vue3-numeric-input';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome, faUserSecret, faPiggyBank, faVault, faBell, faCommentDots, faCaretDown, faUserTie, faUnlockAlt, faBarsStaggered, faCaretUp, faRightFromBracket, faWindowRestore, faCircleUser, faHandHoldingDollar, faMoneyBill, faMoneyBill1Wave, faReply, faHandshake, faGift, faPlay, faTrophy, faCrown, faTableList, faMoneyBillTransfer, faUserGear, faCircleInfo, faClover, faFireFlameCurved, faBomb, faTablet, faPuzzlePiece, faClockRotateLeft, faMagnifyingGlassPlus, faComments, faEye } from '@fortawesome/free-solid-svg-icons'

library.add(faHome, faUserSecret, faPiggyBank, faVault, faBell, faCommentDots, faCaretDown, faUserTie, faUnlockAlt, faBarsStaggered, faCaretUp, faRightFromBracket, faWindowRestore, faCircleUser, faHandHoldingDollar, faMoneyBill, faMoneyBill1Wave, faReply, faHandshake, faGift, faPlay, faTrophy, faCrown, faTableList, faMoneyBillTransfer, faUserGear, faCircleInfo, faClover, faFireFlameCurved, faBomb, faTablet, faPuzzlePiece, faClockRotateLeft, faMagnifyingGlassPlus, faComments, faEye)

// createApp(App).mount('#app')
const app = createApp(App)

app.use(router)
app.use(VueAxios, axios)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueSweetalert2)
app.use(Paginate)
app.use(VueCookies);

var domain_web = 'https://adm.king88life.net/api/'

app.config.globalProperties.api_linkweb = 'https://adm.king88life.net/'
app.config.globalProperties.linkwebLive = 'https://tolive.fun'
app.config.globalProperties.api_infouser = domain_web + 'info'
app.config.globalProperties.api_listGame = domain_web + 'list-game'
app.config.globalProperties.api_playGame = domain_web + 'game'
app.config.globalProperties.api_logout = domain_web + 'logout'
app.config.globalProperties.api_wallettogame = domain_web + 'wallet-to-game'
app.config.globalProperties.api_wallettolive = domain_web + 'wallet-to-live'
app.config.globalProperties.api_gametowallet = domain_web + 'game-to-wallet'
app.config.globalProperties.api_listBank = domain_web + 'list-bank'
app.config.globalProperties.api_cashIn = domain_web + 'credit-wallet'
app.config.globalProperties.api_cashout = domain_web + 'debit-wallet'
app.config.globalProperties.api_listBankOut = domain_web + 'list-bank-debit'

app.config.globalProperties.api_historyCashIn = domain_web + 'history-credit'
app.config.globalProperties.api_historyCashout = domain_web + 'history-debit'
app.config.globalProperties.api_historyWalletToGame = domain_web + 'history-wallet-to-game'
app.config.globalProperties.api_historyWalletToIdol = domain_web + 'history-wallet-to-idol'
app.config.globalProperties.api_historyGameToWallet = domain_web + 'history-game-to-wallet'
app.config.globalProperties.api_historyBetting = domain_web + 'history-bet'
app.config.globalProperties.api_reportBet = domain_web + 'report-bet'
app.config.globalProperties.api_cancalCashout = domain_web + 'cancel-debit-wallet'
app.config.globalProperties.api_refreshToken = domain_web + 'refresh'


app.use(store)
app.directive('numeric-input', NumericInput)
app.mount('#app')
