<template>
    <div id="header_city8888">
        <section class="banner-section w-ba">
            <img class="banner-bg" src="../assets/images/banner-bg.png">
            <div class="container h-100 dflex-ac-jc">
                <div class="center logo-city8888">
                    <router-link class="logo" v-bind:to="{ name: 'Home_king' }">
                        <img class="" src="../assets/images/logo.png">
                    </router-link>
                </div>
                <div class="banner-background w-ba">
                    <div class="glow-left">
                        <img src="../assets/images/glow-left.png">
                    </div>
                    <img class="casino-items" src="../assets/images/casino-items.png">
                    <img class="girl-right" src="../assets/images/girl-right.png">
                    <img class="girl-left" src="../assets/images/girl-left.png">
                    <div class="glow-right">
                        <img src="../assets/images/glow-right.png">
                    </div>
                    <img class="candy-slot" src="../assets/images/candy-slot.png">
                    <img class="money-slot" src="../assets/images/money-slot.png">
                </div>
            </div>
        </section>
        <header class="header-section w-ba header-city88">
            <div class="container dflex-ac-jc h-100 max-width-gib header-mb">
                <ul class="bs-ul main-menu sidebar-left">
                    <li>
                        <router-link to="/" class="dflex-ac-jc w-ba">
                            <font-awesome-icon icon="fa-solid fa-home" />&nbsp; Trang chủ
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cash-in" class="dflex-ac-jc w-ba">
                            <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; Nạp tiền
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cash-out" class="dflex-ac-jc w-ba">
                            <font-awesome-icon icon="fa-solid fa-vault" />&nbsp; Rút tiền
                        </router-link>
                    </li>
                    <li class="convert_desktop">
                        <router-link to="/transfer-money" class="dflex-ac-jc w-ba">
                            <font-awesome-icon icon="fa-solid fa-money-bill-transfer" />&nbsp; Quy đổi
                        </router-link>
                    </li>
                    <li class="convert_desktop">
                        <router-link to="/betting" class="dflex-ac-jc w-ba">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />&nbsp; Lịch sử
                        </router-link>
                    </li>
                </ul>
                <div class="bal-container info-login-game">
                    <div class="login-info">
                        <div class="list-info">
                            <ul>
                                <li>
                                    <div><font-awesome-icon icon="fa-solid fa-hand-holding-dollar" /></div>
                                    <div>
                                        <p>Ví tổng</p>
                                        <p class="walletAll">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(wallet) }} vnđ</p>
                                    </div>
                                </li>
                                <li>
                                    <div><font-awesome-icon icon="fa-solid fa-money-bill-1-wave" /></div>
                                    <div>
                                        <p>Ví game</p>
                                        <p class="walletgame">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }} xu</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="more-account">
                            <ul>
                                <li class="account_edit">
                                    <font-awesome-icon icon="fa-solid fa-user-gear" />
                                    <ul>
                                        <li>
                                            <a>
                                                <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-circle-user" /></span>
                                                <span class="span_tit">Họ tên: </span><span class="span_content">{{ fullname }}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-hand-holding-dollar" /></span>
                                                <span class="span_tit">Ví game: </span><span class="span_content walletgame">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }} xu</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-hand-holding-dollar" /></span>
                                                <span class="span_tit">Ví idol: </span><span class="span_content walletidol">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(point) }} đậu</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                            <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-money-bill-1-wave" /></span>
                                                <span class="span_tit">Ví tổng: </span><span class="span_content walletAll">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(wallet) }} vnd</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li @click="logout" class="logout_edit">
                                    <font-awesome-icon icon="fa-solid fa-right-from-bracket" />&nbsp; Đăng xuất
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
            <div class="windows8"> 
                <img src="../assets/images/loading.gif" alt="">
            </div>
        </div>
        <!-- <button id="pagetop" v-show="scY > 300" @click="toTop" class="scroll-top w-ba"><font-awesome-icon icon="fa-solid fa-caret-up" /></button> -->
        <div class="icon_fixed_right">
            <ul>
                <li><a href="https://www.facebook.com/profile.php?id=61560761842147" target="_blank"><img src="../assets/images/facebook.png" alt=""></a></li>
                <li><a href="https://t.me/king88life" target="_blank"><img src="../assets/images/tele.gif" alt=""></a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import $ from 'jquery'
$( document ).ready(function() {
    // $(document).on('click', '.delete_popup', function() {});
});
export default {
    name: 'Header_city',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            wallet: '',
            amount: '',
            fullname: '',
            point: '',
            username: '',
            orther: []
        }
    },
    mounted () {
        // window.addEventListener('scroll', this.handleScroll)
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        const url = window.location.origin
        if(token != null){
            window.localStorage.setItem('token', token)
            setTimeout(() => {
                window.location.href = url
            }, 2000);
        }
    },
    created () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        if(this.token != null && token == null){
            this.getListInfo()
            setInterval(this.getListInfo, 30000);
        }

        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let timestamp = date + ' ' + time;
        if(token != null){
            window.localStorage.setItem('time_token', timestamp)
        }
        this.resettoken()
    },
    components: {
        
    },
    methods: {
        logout (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout, 
                {
                    
                },
                {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.href = res.data.data
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.amount = response.data.amount
                this.wallet = response.data.wallet
                this.point = response.data.point
                this.fullname = response.data.fullname
                window.localStorage.setItem('usn', response.data.username)
            }).catch(e => {
                // this.orther = e
                // console.log(e);
                if(e.response.status == 401){
                    // this.$swal({
                    //     position: 'top-end',
                    //     icon: 'error',
                    //     title: 'Kết thúc phiên đăng nhập',
                    //     showConfirmButton: false,
                    //     timer: 4000
                    // })
                    // localStorage.clear();
                    // setTimeout(() => {
                    //     window.location.href = this.linkwebLive
                    // }, 2000);
                    this.resettoken()
                }
            })
        },
        resettoken () {
            if(this.token !== null){
                let timetk = window.localStorage.getItem('time_token')
                let today = new Date();
                let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                let timestamp = date + ' ' + time;
                let timereset = Math.round(+new Date(timestamp)/1000) - Math.round(+new Date(timetk)/1000)
                let result = timereset / 60
                if(result > 50){
                    this.axios.post(this.api_refreshToken,
                        {

                        },
                        {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }).then((response) => {
                        window.localStorage.setItem('token', response.data.token)
                        let today = new Date();
                        let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                        let timestamp = date + ' ' + time;
                        window.localStorage.setItem('time_token', timestamp)
                    }).catch(e => {
                        if (e?.response?.status === 500 || e?.response?.status === 405) {
                            localStorage.clear();
                            window.location.href = this.linkwebLive
                        }
                    })
                }
            }
        },
    }
}
</script>
<style>
</style>
