<template>
    <div class="wrapper-form wrapper-form_edit page-content">
        <div class="frm_transfer">
            <form method="post" @submit="cashIn">
                <p class="p_text">Ví tổng</p>
                <div class="info-money">
                    <input type="text" :value="new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(wallet) + ' vnđ'" class="moneyinfo">
                </div>
                <p class="p_text">Số tiền</p>
                <div class="money-transfer">
                    <!-- <input type="number" placeholder="0" name="amount" v-model="amount" class="numbermoney"> -->
                    <input v-numeric-input="{ thousands: ',', align: 'left'}" class="numbermoney" placeholder="0" />
                </div>
                <div class="choseBank">
                    <ul>
                        <li v-for="(item, index) in listBank" :key="index">
                            <label :class="'label_check label_check' + index">
                                <!-- {{ item.name }} -->
                                <img :src="api_linkweb + item.image" alt="">
                                <!-- <input type="radio" v-if="index == 0" checked name="checkbank" :value="item.id" class="checkbank" @click="getDataCheck()"> -->
                                <input type="radio" name="checkbank" :value="item.id" :data_bankcode="item.bank_code" class="checkbank" @click="getDataCheck($event)">
                                <span class="checkmark"></span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="bankItem">
                    <Multiselect
                        v-model="bank"
                        :options="listBankcredit"
                        placeholder="Ngân hàng"
                        label="name"
                        :searchable="true"
                    />
                </div>
                <div class="btn-cashin">
                    <button type="submit" class="btn_submit_transfer">Gửi</button>
                </div>
            </form>
        </div>
        <div class="wrapper-listmoney">
            <h2 class="title_transfer">Lịch sử nạp tiền</h2>
            <div class="table-list">
                <table>
                    <thead>
                        <tr>
                            <th style="width: 5%;">STT</th>
                            <th>Số tiền nạp</th>
                            <th>Số tiền quy đổi</th>
                            <th>Ngày nạp (GMT +8)</th>
                            <th>Trạng thái</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data" :key="index">
                            <td style="width: 5%;">{{ index+1 }}</td>
                            <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                            <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount_change) }}</td>
                            <td>{{ item.date_created }}</td>
                            <td v-if="item.situation == 'new'" class="yellowColor">Chờ duyệt</td>
                            <td v-else-if="item.situation == 'approve'" class="greenColor">Đã duyệt</td>
                            <td v-else class="redColor">Hủy</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Multiselect from '@vueform/multiselect'
$( document ).ready(function() {
});
export default {
    name: 'Cashin_city',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            wallet: '',
            amount: 0,
            listBank: [],
            listBankcredit: [],
            bank: '',
            banks: '',
            listHistoryCashIn: [],
            page: 1,
            perPage: 15,
            totalCount: 0,
            data: [],
        }
    },
    created () {
        this.getListInfo()
        this.getListBank()
        this.getHistoryCashIn()
    },
    components: {
        Multiselect
    },
    methods: {
        cashIn (e) {
            $('.loading_show').show().fadeIn(10)
            e.preventDefault()
            let idBank = $('.checkbank:checked').val();
            let amountCash = $('.numbermoney').val()
            this.axios.post(this.api_cashIn, 
                {
                    balance: amountCash,
                    bank_id: idBank,
                    bank_code: this.bank
                },
                { headers: {
                    Authorization: 'Bearer ' + this.token
                }},
            ).then(res => {
                $('#loading_show').css('display', 'none')
                var size = {
                    width: window.innerWidth || document.body.clientWidth,
                    height: window.innerHeight || document.body.clientHeight
                };
                var width = size.width;
                var height = size.height;
                var left = (window.screen.width / 2) - (width/2);
                var top = (window.screen.height / 4);
                var windowStatus = 'width='+width+', height='+height+', left='+left+', top='+top+', scrollbars=yes, status=yes, resizable=yes, titlebar=yes';
                const url = res.data.url;
                window.open(url, "Cashin", windowStatus);
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                $('#loading_show').css('display', 'none')
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                // console.log('111', response);
                this.wallet = response.data.wallet
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                // localStorage.clear();
                // window.location.href = '/'
            })
        },
        getListBank () {
            this.axios.get(this.api_listBank, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listBank = response.data.data
                this.listBankcredit = response.data.bank_credit
            })
        },
        getDataCheck (event) {
            let bankCode = event.target.getAttribute('data_bankcode')
            if (bankCode == '' || bankCode == null) {
                $('.bankItem').show()
                this.bank = ''
            }else {
                $('.bankItem').hide()
                this.bank = bankCode
            }

        },
        getHistoryCashIn () {
            this.axios.get(this.api_historyCashIn, 
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listHistoryCashIn = response.data.data
                this.totalCount = this.listHistoryCashIn.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.data = this.listHistoryCashIn.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
